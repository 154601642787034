<template>
  <b-card
    class="rounded-top border-0 rounded-bottom-0"
    header-class="bg-white border-0 pt-4"
    body-class="pt-0"
  >
    <template #header>
      <b-button pill variant="outline-primary" :to="linkToReturn" class="mb-2">
        <b-icon icon="arrow-left" />
        Voltar
      </b-button>
      <b-row no-gutters align-v="center">
        <b-col cols="12">
          <card-header
            :caption-top="objectiveTitle"
            :label="actionName"
            class="pt-2 border-0"
            label-class="h5"
            :show-status="false"
          />
        </b-col>
      </b-row>
    </template>
    <slot></slot>
  </b-card>
</template>
<script setup>
import { computed } from "vue";

import CardHeader from "@/components/Home/Card/CardHeader.vue";
import { useMapGetters } from "@/composables/useMapGetters";
import { useRoute } from "@/composables/useRoute";

const $route = useRoute();

const { GetNamePlan, GetObjectiveName, GetObjectiveId } = useMapGetters(
  "action_plans",
  ["GetNamePlan", "GetObjectiveName", "GetObjectiveId"]
);

const { getSourceObjectiveName } = useMapGetters("objectives", {
  getSourceObjectiveName: "getObjectiveName",
});

const objectiveIdByQueryParams = computed(() => $route.query.objectiveId);
const isCreateMode = computed(() => !!objectiveIdByQueryParams.value);

const objectiveId = computed(() =>
  isCreateMode.value ? objectiveIdByQueryParams.value : GetObjectiveId()
);

const linkToReturn = computed(() => ({
  name: "objectives_show",
  params: { id: objectiveId.value },
}));

const actionName = computed(() =>
  !isCreateMode.value ? GetNamePlan() : "Criar plano de ação"
);
const objectiveTitle = computed(
  () => GetObjectiveName() || getSourceObjectiveName()
);
</script>
<style scoped lang="scss">
.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>
