import {
  getModuleByNamespace,
  isValidMap,
  normalizeMap,
  normalizeNamespace,
} from "@/helpers/functions/normalizeMap";
import { useStore } from "@/composables/useStore";

/**
 * @param {string} storeName
 * @param {* @param {{} | string[]} map} map
 * @returns {import('vuex').Mapper<import("vuex").Computed>}
 */
export function useMapGetters(storeName, map) {
  const $store = useStore();

  const getters = {};
  normalizeNamespace((storePath, map) => {
    if (!isValidMap(map)) {
      console.error(
        "[vuex] mapActions: mapper parameter must be either an Array or an Object"
      );
      return getters;
    }
    for (const ref of normalizeMap(map)) {
      ref.value = storePath + ref.value;

      getters[ref.key] = function mappedGetters() {
        if (storePath && !getModuleByNamespace($store, storePath)) {
          return;
        }
        if (!(ref.value in $store.getters)) {
          console.error(`[vuex] unknown getter: ${ref.value}`);
          return;
        }
        return $store.getters[ref.value];
      };

      getters[ref.key].vuex = true;
    }
  })(storeName, map);
  return getters;
}
