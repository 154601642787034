<template>
  <div>
    <div class="text-muted font-weight-medium h6 d-flex">
      Responsável

      <div v-if="isVisibility" class="ml-auto">
        <b-form-radio-group
          v-model="selected_type"
          :options="[
            {
              value: 'organizational_structure',
              text: 'Estrutura Organizacional',
            },
            {
              value: 'physical_person',
              text: 'Pessoas Físicas',
            },
          ]"
          @click="formModel = null"
        />
      </div>
    </div>
    <b-form-group v-if="isVisibility">
      <template v-if="selected_type == 'organizational_structure'">
        <StructureSelect v-model="formModel" />
      </template>
      <template v-else-if="selected_type == 'physical_person'">
        <Responsible v-model="formModel" />
      </template>
    </b-form-group>
    <div v-else class="column text-center mb-4 mt-5">
      <b-icon icon="person-plus" font-scale="2" />
      <div class="h6 font-weight-medium mt-2">Sem responsáveis definidos</div>
      <p class="text-muted">Defina quem serão os responsáveis deste plano</p>
      <b-button
        variant="primary"
        pill
        size="sm"
        @click="visibilityField = true"
      >
        <b-icon icon="plus" />
        Adicionar responsável
      </b-button>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import Responsible from "@/modules/physical_people/components/Responsible";
import StructureSelect from "@/modules/organizational_structures/components/StructureSelect";

const props = defineProps({
  value: {
    type: Number,
    default: null,
  },
  model_type: {
    type: String,
    default: "organizational_structure",
  },
});
const emits = defineEmits(["input", "model_type"]);

const formModel = computed({
  set(value) {
    if (value) {
      visibilityField.value = true;
    }

    emits("input", value);
    emits("model_type", selected_type.value);
  },
  get() {
    return props.value;
  },
});

const selected_type = computed({
  set(value) {
    emits("model_type", value);
  },
  get() {
    return props.model_type ?? "organizational_structure";
  },
});
const visibilityField = ref(false);

const hasValue = computed(
  () => formModel.value && String(formModel.value).length > 0
);

const isVisibility = computed(() =>
  hasValue.value ? true : visibilityField.value
);
</script>
<style scoped lang="scss"></style>
