<template>
  <b-form @submit.prevent="submit">
    <div class="text-muted font-weight-medium h6">Título da ação</div>
    <b-form-group>
      <b-form-input
        v-model="action_plan.name"
        placeholder="Título"
        :state="validateState('name')"
      />
      <b-form-invalid-feedback>
        Este é um campo obrigatório.
      </b-form-invalid-feedback>
    </b-form-group>
    <form-responsaveis
      v-model="action_plan.model_id"
      @model_type="(val) => (action_plan.model_type = val)"
      :model_type="action_plan.model_type"
      :state="validateState('responsavel')"
    />
    <div class="row no-gutters">
      <b-button
        class="ml-auto"
        variant="primary"
        type="submit"
        @click="$emit('save')"
      >
        Salvar
      </b-button>
    </div>
  </b-form>
</template>
<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import FormResponsaveis from "@/modules/action_plans/components/Form/FormResponsaveis.vue";

export default {
  components: {
    FormResponsaveis,
  },
  emits: ["input", "submit"],
  model: {
    event: "input",
    prop: "value",
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    action_plan: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async submit() {
      this.$v.action_plan.$touch();
      if (this.$v.action_plan.$anyError) {
        this.$notifications.addNotification({
          message: "Os dados do formulário são inválidos",
          type: "danger",
        });
        return;
      }

      try {
        this.$emit("submit");
      } catch (error) {
        console.log("ERROR", error);
      }
    },
    validateState(name) {
      const indexes = name.split(".");
      let validations = this.$v.action_plan;
      for (let i in indexes) {
        validations = validations[indexes[i]];
      }
      const { $dirty, $error } = validations;
      return $dirty ? !$error : null;
    },
    setModelType(value) {
      console.log("setModelType", value);
      this.action_plan.model_type = value;
    },
  },
  mixins: [validationMixin],
  validations: {
    action_plan: {
      name: {
        required,
      },
      responsavel: {},
    },
  },
};
</script>
<style scoped lang="scss">
.max-height-description {
  min-height: 80px;
  max-height: 200px;
}
</style>
