var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"text-muted font-weight-medium h6 d-flex"},[_vm._v(" Responsável "),(_setup.isVisibility)?_c('div',{staticClass:"ml-auto"},[_c('b-form-radio-group',{attrs:{"options":[
          {
            value: 'organizational_structure',
            text: 'Estrutura Organizacional',
          },
          {
            value: 'physical_person',
            text: 'Pessoas Físicas',
          },
        ]},on:{"click":function($event){_setup.formModel = null}},model:{value:(_setup.selected_type),callback:function ($$v) {_setup.selected_type=$$v},expression:"selected_type"}})],1):_vm._e()]),(_setup.isVisibility)?_c('b-form-group',[(_setup.selected_type == 'organizational_structure')?[_c(_setup.StructureSelect,{model:{value:(_setup.formModel),callback:function ($$v) {_setup.formModel=$$v},expression:"formModel"}})]:(_setup.selected_type == 'physical_person')?[_c(_setup.Responsible,{model:{value:(_setup.formModel),callback:function ($$v) {_setup.formModel=$$v},expression:"formModel"}})]:_vm._e()],2):_c('div',{staticClass:"column text-center mb-4 mt-5"},[_c('b-icon',{attrs:{"icon":"person-plus","font-scale":"2"}}),_c('div',{staticClass:"h6 font-weight-medium mt-2"},[_vm._v("Sem responsáveis definidos")]),_c('p',{staticClass:"text-muted"},[_vm._v("Defina quem serão os responsáveis deste plano")]),_c('b-button',{attrs:{"variant":"primary","pill":"","size":"sm"},on:{"click":function($event){_setup.visibilityField = true}}},[_c('b-icon',{attrs:{"icon":"plus"}}),_vm._v(" Adicionar responsável ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }