<template>
  <div class="container px-0 mb-5">
    <the-header />
    <b-card class="border-0 radius-top-0 mb-5" body-class="pt-0">
      <b-tabs
        pills
        nav-wrapper-class="mb-4 pb-2 border-bottom"
        @activate-tab="changeTab"
      >
        <b-tab title="Dados básicos" active>
          <form-base @submit="submit" v-model="form" />
        </b-tab>
        <b-tab>
          <template #title>
            <div
              title="As ações só podem ser criadas após a definição dos dados básicos"
              v-b-tooltip.hover.v-warning
              class="text-secondary"
            >
              Ações
            </div>
          </template>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import TheHeader from "@/modules/action_plans/components/TheHeader.vue";
import FormBase from "@/modules/action_plans/components/Form/FormBase.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { TheHeader, FormBase },
  data() {
    return {
      form: {
        name: null,
        model_type: 'organizational_structure',
        model_id: null
      },
      activeTab: 0,
    };
  },
  computed: {
    ...mapState("action_plans", ["action_plan"]),
  },
  methods: {
    ...mapActions("action_plans", ["ActionSave"]),
    changeTab(newTab, oldTab, bvEvent) {
      if (newTab > 0) {
        bvEvent.preventDefault();
      }
    },
    async submit() {
      try {
        this.form.objective_id = this.$route.query.objectiveId;
        await this.ActionSave(this.form);
        this.$notifications.addNotification({
          message: "Registro salvo com sucesso",
          type: "success",
        });
        this.$router.push({
          name: "show_action_plan",
          params: {
            id: this.action_plan.id,
          },
        });
      } catch (error) {
        this.$notifications.addNotification({
          message: "Erro ao salvar",
          type: "danger",
        });
        console.log("ERROR", error);
      }
    },
  },
};
</script>

