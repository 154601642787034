<template>
  <div>
    <div class="d-flex align-items-center">
      <small :class="colorIconClass" class="status d-flex align-items-center">
        <template v-if="showStatus">
          <b-icon icon="circle-fill" aria-hidden="true" class="mr-2" />
          {{ statusLabel }}
        </template>
        <div class="text-muted" :class="{ 'ml-1': showStatus }">
          {{ captionTop }}
        </div>
      </small>
    </div>
    <div class="h3 pt-2 d-flex align-items-center align-content-center" :class="labelClass">
      <font-awesome-icon v-if="icon" :icon="icon" class="h6 mb-0 mr-2" />
      {{ label }}
    </div>
  </div>
</template>
<script>
export default {
  name: "CardHeader",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    showStatus: {
      type: Boolean,
      default: true
    },
    captionTop: {
      type: String,
      default: ""
    },
    icon: {
      type: [String, Array],
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    labelClass: {
      type: [String, Object],
      default: ""
    }
  },
  computed: {
    statusLabel() {
      return this.active ? "Habilitado" : "Desabilitado";
    },
    colorIconClass() {
      return this.active ? "green" : "orange";
    }
  }
};
</script>
<style scoped lang="scss">
.status {
  font-size: 0.75em;

  .b-icon {
    font-size: 0.75em;
  }
}

.green {
  color: #00a332;
}

.orange {
  color: #fe9d00;
}
</style>
