/**
 * @param {*} obj
 */
const isObject = (obj) => typeof obj === "object" && !Array.isArray(obj);
/**
 * @param {[] | {}} map
 */
export const isValidMap = (map) => isObject(map) || Array.isArray(map);

/**
 * @param {{ [key: string]: string } | string[]} map
 * @returns {{ key: string, value: string }[]}
 */
export function normalizeMap(map) {
  if (!isValidMap(map)) {
    return [];
  }

  const mapToSameValue = (key) => ({ key, value: key });
  const mapToDictionary = (key) => ({ key, value: map[key] });

  return Array.isArray(map)
    ? map.map(mapToSameValue)
    : Object.keys(map).map(mapToDictionary);
}

export function normalizeNamespace(fn) {
  return (namespace, map) => {
    if (typeof namespace !== "string") {
      map = namespace;
      namespace = "";
    } else if (namespace.charAt(namespace.length - 1) !== "/") {
      namespace += "/";
    }
    return fn(namespace, map);
  };
}

/**
 * @param {*} store
 * @param {string} namespace
 */
export function getModuleByNamespace(store, namespace) {
  const module = store._modulesNamespaceMap[namespace];
  if (!module) {
    console.error("[vuex] Invalid namespace");
  }
  return module;
}
